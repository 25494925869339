import { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import { useRouter } from "next/router"
import { Transition } from "react-transition-group"

import Modal from "../elements/modal"
import Logo from "../elements/logo"
import Register from "../elements/register"

const Leadform = ({ data }) => {
  const modalRef = useRef()
  const router = useRouter()
  const { asPath } = router
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)

    document.querySelector("body").classList.remove("overflow-hidden")

    router.push(
      {
        pathname: "/",
      },
      undefined,
      { shallow: true }
    )
  }

  useEffect(() => {
    if (asPath === "/#register") {
      setShow(true)
      document.querySelector("body").classList.add("overflow-hidden")
    }
    return () => {
      document.querySelector("body").classList.remove("overflow-hidden")
    }
  }, [asPath])

  useEffect(() => {
    const clickOutsideContent = (e) => {
      if (e.target === modalRef.current) {
        handleClose()
      }
    }
    window.addEventListener("click", clickOutsideContent)
    return () => {
      window.removeEventListener("click", clickOutsideContent)
    }
  }, [show])

  return (
    <Transition in={show} timeout={300} unmountOnExit>
      {(state) => (
        <div
          className={classNames("modal", {
            "animate-fadeIn": show,
            "animate-fadeOut": !show,
          })}
          aria-hidden={show ? "true" : "false"}
        >
          <div
            className={classNames(
              "outline-none focus:outline-none fixed inset-0 z-50 overflow-x-hidden md:flex md:items-center md:justify-center",
              {
                "md:animate-slideIn": show,
                "md:animate-slideOut": !show,
              }
            )}
            ref={modalRef}
          >
            <div className="mx-auto h-full w-full max-w-md md:h-auto">
              <div className="outline-none focus:outline-none flex h-full w-full flex-col rounded-lg border-0 bg-white shadow-lg md:max-h-90vh">
                <div className="border-slate-200 flex items-start justify-between rounded-t border-solid p-5">
                  <Logo base />
                  <button
                    className="outline-none focus:outline-none float-right ml-auto border-0 bg-transparent px-1 text-5xl font-semibold leading-none text-black duration-200 ease-linear hover:text-accent"
                    onClick={handleClose}
                  >
                    <span className="opacity-3 outline-none focus:outline-none flex h-6 w-6 items-center bg-transparent text-4xl">
                      ×
                    </span>
                  </button>
                </div>
                <div className="relative grid h-full place-items-center overflow-y-auto p-6 md:h-auto md:flex-auto">
                  <Register data={data} />
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </div>
      )}
    </Transition>
  )
}

export default Leadform
