import RichText from "@/components/elements/rich-text"
import classNames from "classnames"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "../../utils/button"
import NextImage from "@/components/elements/image"

export const QuizDoubleResults = ({ double, leftRes, rightRes }) => {
  const title = double.title
  const text = double.text
  const image = double.image

  return (
    <>
      {title && (
        <RichText
          style={
            "mx-auto mb-[48px] max-w-[492px] px-1 text-center text-[28px] leading-[34px] md:mb-0 md:text-[24px]"
          }
          content={title}
        />
      )}
      <ul className="list md:flex md:justify-between">
        <li className="mb-10 md:mb-0 md:max-w-[230px] md:pt-[95px] lg:max-w-[300px] xl:max-w-[370px]">
          {leftRes.text && (
            <RichText
              style={classNames("mb-6 text-center text-[20px] lg:text-[24px]")}
              content={leftRes.text}
            />
          )}
          <ButtonLink
            style={classNames("")}
            button={leftRes.btn}
            appearance={getButtonAppearance(leftRes.btn.type, "light")}
            stylesText={
              "!bg-[#E20A19] !border-[#E20A19] !rounded-[50px] hover:!text-[#E20A19] hover:!bg-white"
            }
          />
        </li>
        <li>
          {text && (
            <RichText
              style={
                "mb-10 text-center text-[28px] max-w-[250px] mx-auto lg:max-w-none md:text-[24px]"
              }
              content={text}
            />
          )}
        </li>
        <li className="mb-10 md:mb-0 md:max-w-[230px] md:pt-[95px] lg:max-w-[300px] xl:max-w-[370px]">
          {rightRes.text && (
            <RichText
              style={classNames("mb-6 text-center text-[20px] lg:text-[24px]")}
              content={rightRes.text}
            />
          )}
          <ButtonLink
            style={classNames("")}
            button={rightRes.btn}
            appearance={getButtonAppearance(rightRes.btn.type, "light")}
            stylesText={
              "!bg-[#127C41] !border-[#127C41] !rounded-[50px] hover:!text-[#127C41] hover:!bg-white"
            }
          />
        </li>
      </ul>

      <div className="mx-auto md:max-w-[500px] xl:mt-[-50px] xl:max-w-[550px]">
        <NextImage media={image} />
      </div>
    </>
  )
}
