import { useRef, useEffect } from "react"
import classNames from "classnames"
import { Transition } from "react-transition-group"

import Logo from "./logo"

export default function Modal({
  show,
  setShow,
  showClose = true,
  title,
  logo = false,
  children,
}) {
  const modalRef = useRef()

  useEffect(() => {
    const clickOutsideContent = (e) => {
      if (e.target === modalRef.current) {
        setShow(false)
      }
    }
    window.addEventListener("click", clickOutsideContent)

    if (show) {
      document.querySelector("body").classList.add("overflow-hidden")

      return () => {
        document.querySelector("body").classList.remove("overflow-hidden")
      }
    }

    return () => {
      window.removeEventListener("click", clickOutsideContent)
    }
  }, [show])

  return (
    <Transition in={show} timeout={300} unmountOnExit>
      {(state) => (
        <div
          className={classNames("modal", {
            "animate-fadeIn": show,
            "animate-fadeOut": !show,
          })}
          aria-hidden={show ? "true" : "false"}
        >
          <div
            className={classNames(
              "outline-none focus:outline-none fixed inset-0 z-50 overflow-x-hidden md:flex md:items-center md:justify-center",
              {
                "md:animate-slideIn": show,
                "md:animate-slideOut": !show,
              }
            )}
            ref={modalRef}
          >
            <div className="mx-auto h-full w-full md:h-auto md:max-w-xl lg:max-w-4xl">
              <div className="outline-none focus:outline-none flex h-full w-full flex-col rounded-lg border-0 bg-white shadow-lg md:max-h-90vh">
                {(showClose || title) && (
                  <div
                    className={classNames(
                      "border-slate-200 flex items-start justify-between rounded-t border-solid p-5",
                      { "border-b": title }
                    )}
                  >
                    {logo && (
                      <div className={classNames({ "mr-8": title })}>
                        <Logo base />
                      </div>
                    )}
                    {title && (
                      <h3 className="text-2xl font-semibold">{title}</h3>
                    )}
                    {showClose && (
                      <button
                        className="outline-none focus:outline-none float-right ml-auto border-0 bg-transparent p-1 text-5xl font-semibold leading-none text-black"
                        onClick={() => setShow(false)}
                      >
                        <span className="opacity-3 outline-none focus:outline-none flex h-6 w-6 items-center bg-transparent text-4xl text-black">
                          ×
                        </span>
                      </button>
                    )}
                  </div>
                )}
                <div className="relative grid h-full overflow-y-auto p-6 md:h-auto md:flex-auto md:place-items-center">
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </div>
      )}
    </Transition>
  )
}
