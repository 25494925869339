import classNames from "classnames"
import PropTypes from "prop-types"
import { buttonLinkPropTypes } from "utils/types"
import CustomLink from "./custom-link"

const ButtonContent = ({ button, compact, appearance, styles = "" }) => {
  return (
    <div
      className={classNames(
        // Common classes
        "easy-linear flex w-full justify-center rounded-2xl border text-center font-semibold tracking-wide duration-300 md:w-auto",
        // Full-size button
        {
          "px-5 py-6 text-lg font-bold": compact === false,
        },
        // Compact button
        {
          "px-6 py-2": compact === true,
        },
        // Specific to when the button is fully dark
        {
          "border-accent bg-accent text-white hover:bg-transparent hover:text-accent":
            appearance === "dark",
        },
        // Specific to when the button is dark outlines
        {
          "border-accent text-accent hover:border-white hover:bg-accent hover:text-white":
            appearance === "dark-outline",
        },
        // Specific to when the button is fully white
        {
          "border-accent bg-white text-accent hover:border-white hover:bg-accent hover:text-white":
            appearance === "white",
        },
        // Specific to when the button is white outlines
        {
          "border-white text-white hover:border-accent hover:text-accent":
            appearance === "white-outline",
        },
        styles
      )}
    >
      {button.text}
    </div>
  )
}

const ButtonLink = ({
  button,
  appearance,
  compact = false,
  style = "",
  stylesText = "",
}) => {
  return (
    <CustomLink link={button} style={style}>
      <ButtonContent
        button={button}
        appearance={appearance}
        compact={compact}
        styles={stylesText}
      />
    </CustomLink>
  )
}

ButtonLink.propTypes = {
  button: buttonLinkPropTypes,
  appearance: PropTypes.oneOf([
    "dark",
    "white-outline",
    "white",
    "dark-outline",
  ]),
  compact: PropTypes.bool,
}

export default ButtonLink
