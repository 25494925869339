import { useSelector, shallowEqual } from "react-redux"
import { useTranslation } from "next-i18next"

const Cabinet = ({ data }) => {
  const { user } = useSelector((state) => state, shallowEqual)
  const { t } = useTranslation("common")

  if (!user.token) {
    return (
      <div className="container text-center">
        <p>{t("redirect")}</p>
      </div>
    )
  }

  const { name } = user

  return (
    <div className="section container">
      <h1 className="text-center text-4xl font-bold md:text-5xl">
        {data.title}
        {name}
      </h1>
    </div>
  )
}

export default Cabinet
