import React, { useEffect, useState } from "react"
import Link from "next/link"

import { useRouter } from "next/router"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { logoutUser } from "../../redux/userSlice"
import { resetQuiz } from "../../redux/quizSlice"
import { resetCounter } from "../../redux/counterSlice"

import Button from "./button"
import { getButtonAppearance } from "../../utils/button"
import { useTranslation } from "next-i18next"

import MobileNavMenu from "./mobile-nav-menu"
import { MdMenu } from "react-icons/md"

const ProfileMenu = ({ pagePath }) => {
  const [userForRender, setUserForRender] = useState({})
  const dispatch = useDispatch()
  const router = useRouter()
  const { asPath } = router

  const { t } = useTranslation("profile-menu")

  const user = useSelector((state) => state.user, shallowEqual)

  const logoutHandler = () => {
    router.push("/login")
    dispatch(resetCounter())
    dispatch(resetQuiz())
    dispatch(logoutUser())
  }

  useEffect(() => {
    setUserForRender(user)
  }, [user])

  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false)

  return (
    <div className="flex items-center">
      <button
        aria-label={t("mobileMenuOpen")}
        onClick={() => setMobileMenuIsShown(true)}
        className={`block p-1  ${
          pagePath ? "text-black" : "text-white"
        } md:hidden`}
      >
        <MdMenu className="h-8 w-auto" />
      </button>

      {mobileMenuIsShown && (
        <MobileNavMenu
          pagePath={pagePath}
          closeSelf={() => setMobileMenuIsShown(false)}
        />
      )}
      {!userForRender.token ? (
        <div className="user_pic hidden items-center justify-center rounded-[16px] border-none bg-accent p-[14px] text-white md:flex">
          {asPath !== "/login" && (
            <Link href="/login">
              <a
                className={
                  pagePath
                    ? "borderOnExit pr-[4px] text-[14px] font-bold leading-[1.7]"
                    : "pr-[4px] text-[14px] font-bold leading-[1.7]"
                }
              >
                {t("login")}
              </a>
            </Link>
          )}
          {asPath !== "/register" && (
            <Link href="/#register">
              <a className="ml-[4px] text-[14px] font-bold leading-[1.7]">
                {t("register")}
              </a>
            </Link>
          )}
        </div>
      ) : (
        <div className="hidden items-center justify-center md:flex">
          {asPath !== "/cabinet" && (
            <Link href="/cabinet">
              <a className="mr-2 rounded-[16px] border border-accent bg-accent py-[15px] px-[20px] text-[14px] font-bold leading-[1.33] text-white duration-200  hover:scale-110 hover:bg-transparent hover:text-accent  ">
                {t("cabinet")}
              </a>
            </Link>
          )}
          <Button
            type="button"
            button={{ text: t("logout") }}
            appearance={getButtonAppearance("button", "light")}
            handleClick={logoutHandler}
            stylesText={`py-[15px] text-[14px] lg:text-[14px]  hover:scale-110 duration-200`}
          />
        </div>
      )}
    </div>
  )
}

export default ProfileMenu
