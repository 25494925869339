const uk = {
  ext: "дод.",
  country: "Країна",
  phone: "Телефон",
  AC: "Острів Вознесіння",
  AD: "Андорра",
  AE: "Об'єднані Арабські Емірати",
  AF: "Афганістан",
  AG: "Антигуа і Барбуда",
  AI: "Ангілья",
  AL: "Албанія",
  AM: "Вірменія",
  AO: "Ангола",
  AR: "Аргентина",
  AS: "Американське Самоа",
  AT: "Австрія",
  AU: "Австралія",
  AW: "Аруба",
  AX: "Аландські острови",
  AZ: "Азербайджан",
  BA: "Боснія і Герцеговина",
  BB: "Барбадос",
  BD: "Бангладеш",
  BE: "Бельгія",
  BF: "Буркіна-Фасо",
  BG: "Болгарія",
  BH: "Бахрейн",
  BI: "Бурунді",
  BJ: "Бенін",
  BL: "Сен-Бартельмі",
  BM: "Бермудські Острови",
  BN: "Бруней",
  BO: "Боливия",
  BQ: "Карибські Нідерланди",
  BR: "Бразилія",
  BS: "Багамські Острови",
  BT: "Бутан",
  BW: "Ботсвана",
  BY: "Білорусь",
  BZ: "Беліз",
  CA: "Канада",
  CC: "Кокосові Острови",
  CD: "ДР Конго",
  CF: "Центральноафриканська Республіка",
  CG: "Республіка Конго",
  CH: "Швейцарія",
  CI: "Кот-д'Івуар",
  CK: "Острови Кука",
  CL: "Чилі",
  CM: "Камерун",
  CN: "КНР",
  CO: "Колумбія",
  CR: "Коста-Рика",
  CU: "Куба",
  CV: "Кабо-Верде",
  CW: "Кюрасао",
  CX: "Остров Різдва",
  CY: "Кіпр",
  CZ: "Чехія",
  DE: "Німеччина",
  DJ: "Джибуті",
  DK: "Данія",
  DM: "Домініка",
  DO: "Домініканська Республіка",
  DZ: "Алжир",
  EC: "Еквадор",
  EE: "Естонія",
  EG: "Єгипет",
  EH: "Західна Сахара",
  ER: "Еритрея",
  ES: "Іспания",
  ET: "Ефіопія",
  FI: "Фінляндія",
  FJ: "Фіджі",
  FK: "Фолклендські Острови",
  FM: "Федеративні Штати Мікронезії",
  FO: "Фарерські Острови",
  FR: "Франція",
  GA: "Габон",
  GB: "Велика Британія",
  GD: "Гренада",
  GE: "Грузія",
  GF: "Французька Гвіана",
  GG: "Гернсі",
  GH: "Гана",
  GI: "Гібралтар",
  GL: "Гренландія",
  GM: "Гамбія",
  GN: "Гвінея",
  GP: "Гваделупа",
  GQ: "Екваторіальна Гвінея",
  GR: "Греція",
  GT: "Гватемала",
  GU: "Гуам",
  GW: "Гвінея-Бісау",
  GY: "Гаяна",
  HK: "Гонконг",
  HN: "Гондурас",
  HR: "Хорватія",
  HT: "Гаїті",
  HU: "Угорщина",
  ID: "Індонезія",
  IE: "Ірландія",
  IL: "Ізраїль",
  IM: "Острів Мен",
  IN: "Індія",
  IO: "Британська Територія в Індійському Океані",
  IQ: "Ірак",
  IR: "Іран",
  IS: "Ісландія",
  IT: "Італія",
  JE: "Джерсі",
  JM: "Ямайка",
  JO: "Йорданія",
  JP: "Японія",
  KE: "Кенія",
  KG: "Киргизстан",
  KH: "Камбоджа",
  KI: "Кірибаті",
  KM: "Коморські Острови",
  KN: "Сент-Кіттс і Невіс",
  KP: "Північна Корея",
  KR: "Південна Корея",
  KW: "Кувейт",
  KY: "Кайманові Острови",
  KZ: "Казахстан",
  LA: "Лаос",
  LB: "Ліван",
  LC: "Сент-Люсія",
  LI: "Ліхтенштейн",
  LK: "Шрі-Ланка",
  LR: "Ліберія",
  LS: "Лесото",
  LT: "Литва",
  LU: "Люксембург",
  LV: "Латвія",
  LY: "Лівія",
  MA: "Марокко",
  MC: "Монако",
  MD: "Молдова",
  ME: "Чорногорія",
  MF: "Сен-Мартен",
  MG: "Мадагаскар",
  MH: "Маршаллові Острови",
  MK: "Північна Македонія",
  ML: "Малі",
  MM: "М'янма",
  MN: "Монголія",
  MO: "Макао",
  MP: "Північні Маріанські Острови",
  MQ: "Мартиніка",
  MR: "Мавританія",
  MS: "Монтсеррат",
  MT: "Мальта",
  MU: "Маврикій",
  MV: "Мальдіви",
  MW: "Малаві",
  MX: "Мексика",
  MY: "Малайзія",
  MZ: "Мозамбік",
  NA: "Намібія",
  NC: "Нова Каледонія",
  NE: "Нігер",
  NF: "Острів Норфолк",
  NG: "Нігерія",
  NI: "Нікарагуа",
  NL: "Нідерланди",
  NO: "Норвегія",
  NP: "Непал",
  NR: "Науру",
  NU: "Ніуу",
  NZ: "Нова Зеландія",
  OM: "Оман",
  PA: "Панама",
  PE: "Перу",
  PF: "Французька Полінезія",
  PG: "Папуа Нова Гвінея",
  PH: "Філіппіни",
  PK: "Пакистан",
  PL: "Польща",
  PM: "Сен-П'єр і Мікелон",
  PR: "Пуерто-Рико",
  PS: "Палестина",
  PT: "Португалія",
  PW: "Палау",
  PY: "Парагвай",
  QA: "Катар",
  RE: "Реюньйон",
  RO: "Румунія",
  RS: "Сербія",
  RU: "Росія",
  RW: "Руанда",
  SA: "Саудівська Аравія",
  SB: "Соломонові Острови",
  SC: "Сейшельські Острови",
  SD: "Судан",
  SE: "Швеція",
  SG: "Сінгапур",
  SH: "Острови Святої Єлени, Вознесіння і Тристан-да-Кунья",
  SI: "Словенія",
  SJ: "Свальбард і Ян-Маєн",
  SK: "Словаччина",
  SL: "Сьєрра-Леоне",
  SM: "Сан-Марино",
  SN: "Сенегал",
  SO: "Сомалі",
  SR: "Суринам",
  SS: "Південний Судан",
  ST: "Сан-Томе і Принсіпі",
  SV: "Сальвадор",
  SX: "Сінт-Мартен",
  SY: "Сирія",
  SZ: "Есватіні",
  TA: "Тристан-да-Кунья",
  TC: "Острови Теркс і Кайкос",
  TD: "Чад",
  TG: "Того",
  TH: "Таїланд",
  TJ: "Таджикистан",
  TK: "Токелау",
  TL: "Східний Тимор",
  TM: "Туркменістан",
  TN: "Туніс",
  TO: "Тонга",
  TR: "Туреччина",
  TT: "Тринідад і Тобаго",
  TV: "Тувалу",
  TW: "Тайвань",
  TZ: "Танзанія",
  UA: "Україна",
  UG: "Уганда",
  US: "США",
  UY: "Уругвай",
  UZ: "Узбекистан",
  VA: "Ватикан",
  VC: "Сент-Вінсент і Гренадини",
  VE: "Венесуела",
  VG: "Британські Віргінські Острови",
  VI: "Американські Віргінські Острови",
  VN: "В'єтнам",
  VU: "Вануату",
  WF: "Волліс і Футуна",
  WS: "Самоа",
  XK: "Косово",
  YE: "Ємен",
  YT: "Майотта",
  ZA: "ПАР",
  ZM: "Замбія",
  ZW: "Зімбабве",
};

const ru = {
  ext: "доб.",
  country: "Страна номера телефона",
  phone: "Телефон",
  AC: "Остров Вознесения",
  AD: "Андорра",
  AE: "Объединенные Арабские Эмираты",
  AF: "Афганистан",
  AG: "Антигуа и Барбуда",
  AI: "Ангилья",
  AL: "Албания",
  AM: "Армения",
  AO: "Ангола",
  AR: "Аргентина",
  AS: "Американское Самоа",
  AT: "Австрия",
  AU: "Австралия",
  AW: "Аруба",
  AX: "Эландские острова",
  AZ: "Азербайджан",
  BA: "Босния и Герцеговина",
  BB: "Барбадос",
  BD: "Бангладеш",
  BE: "Бельгия",
  BF: "Буркина-Фасо",
  BG: "Болгария",
  BH: "Бахрейн",
  BI: "Бурунди",
  BJ: "Бенин",
  BL: "Сен-Бартельми",
  BM: "Бермуды",
  BN: "Бруней-Даруссалам",
  BO: "Боливия, Многонациональное Государство",
  BQ: "Бонайре, Саба и Синт-Эстатиус",
  BR: "Бразилия",
  BS: "Багамы",
  BT: "Бутан",
  BW: "Ботсвана",
  BY: "Белоруссия",
  BZ: "Белиз",
  CA: "Канада",
  CC: "Кокосовые (Килинг) острова",
  CD: "Конго, Демократическая Республика",
  CF: "Центрально-Африканская Республика",
  CG: "Конго",
  CH: "Швейцария",
  CI: "Кот д'Ивуар",
  CK: "Острова Кука",
  CL: "Чили",
  CM: "Камерун",
  CN: "Китай",
  CO: "Колумбия",
  CR: "Коста-Рика",
  CU: "Куба",
  CV: "Кабо-Верде",
  CW: "Кюрасао",
  CX: "Остров Рождества",
  CY: "Кипр",
  CZ: "Чешская Республика",
  DE: "Германия",
  DJ: "Джибути",
  DK: "Дания",
  DM: "Доминика",
  DO: "Доминиканская Республика",
  DZ: "Алжир",
  EC: "Эквадор",
  EE: "Эстония",
  EG: "Египет",
  EH: "Западная Сахара",
  ER: "Эритрея",
  ES: "Испания",
  ET: "Эфиопия",
  FI: "Финляндия",
  FJ: "Фиджи",
  FK: "Фолклендские острова (Мальвинские)",
  FM: "Микронезия, Федеративные Штаты",
  FO: "Фарерские острова",
  FR: "Франция",
  GA: "Габон",
  GB: "Соединенное Королевство",
  GD: "Гренада",
  GE: "Грузия",
  GF: "Французская Гвиана",
  GG: "Гернси",
  GH: "Гана",
  GI: "Гибралтар",
  GL: "Гренландия",
  GM: "Гамбия",
  GN: "Гвинея",
  GP: "Гваделупа",
  GQ: "Экваториальная Гвинея",
  GR: "Греция",
  GT: "Гватемала",
  GU: "Гуам",
  GW: "Гвинея-Бисау",
  GY: "Гайана",
  HK: "Гонконг",
  HN: "Гондурас",
  HR: "Хорватия",
  HT: "Гаити",
  HU: "Венгрия",
  ID: "Индонезия",
  IE: "Ирландия",
  IL: "Израиль",
  IM: "Остров Мэн",
  IN: "Индия",
  IO: "Британская территория в Индийском океане",
  IQ: "Ирак",
  IR: "Иран, Исламская Республика",
  IS: "Исландия",
  IT: "Италия",
  JE: "Джерси",
  JM: "Ямайка",
  JO: "Иордания",
  JP: "Япония",
  KE: "Кения",
  KG: "Киргизия",
  KH: "Камбоджа",
  KI: "Кирибати",
  KM: "Коморы",
  KN: "Сент-Китс и Невис",
  KP: "Корея, Народно-Демократическая Республика",
  KR: "Корея, Республика",
  KW: "Кувейт",
  KY: "Острова Кайман",
  KZ: "Казахстан",
  LA: "Лаос",
  LB: "Ливан",
  LC: "Сент-Люсия",
  LI: "Лихтенштейн",
  LK: "Шри-Ланка",
  LR: "Либерия",
  LS: "Лесото",
  LT: "Литва",
  LU: "Люксембург",
  LV: "Латвия",
  LY: "Ливийская Арабская Джамахирия",
  MA: "Марокко",
  MC: "Монако",
  MD: "Молдова, Республика",
  ME: "Черногория",
  MF: "Сен-Мартен",
  MG: "Мадагаскар",
  MH: "Маршалловы острова",
  MK: "Республика Македония",
  ML: "Мали",
  MM: "Мьянма",
  MN: "Монголия",
  MO: "Макао",
  MP: "Северные Марианские острова",
  MQ: "Мартиника",
  MR: "Мавритания",
  MS: "Монтсеррат",
  MT: "Мальта",
  MU: "Маврикий",
  MV: "Мальдивы",
  MW: "Малави",
  MX: "Мексика",
  MY: "Малайзия",
  MZ: "Мозамбик",
  NA: "Намибия",
  NC: "Новая Каледония",
  NE: "Нигер",
  NF: "Остров Норфолк",
  NG: "Нигерия",
  NI: "Никарагуа",
  NL: "Нидерланды",
  NO: "Норвегия",
  NP: "Непал",
  NR: "Науру",
  NU: "Ниуэ",
  NZ: "Новая Зеландия",
  OM: "Оман",
  PA: "Панама",
  PE: "Перу",
  PF: "Французская Полинезия",
  PG: "Папуа-Новая Гвинея",
  PH: "Филиппины",
  PK: "Пакистан",
  PL: "Польша",
  PM: "Сент-Пьер и Микелон",
  PR: "Пуэрто-Рико",
  PS: "Палестинская территория, оккупированная",
  PT: "Португалия",
  PW: "Палау",
  PY: "Парагвай",
  QA: "Катар",
  RE: "Реюньон",
  RO: "Румыния",
  RS: "Сербия",
  RU: "Россия",
  RW: "Руанда",
  SA: "Саудовская Аравия",
  SB: "Соломоновы острова",
  SC: "Сейшелы",
  SD: "Судан",
  SE: "Швеция",
  SG: "Сингапур",
  SH: "Святая Елена, Остров вознесения, Тристан-да-Кунья",
  SI: "Словения",
  SJ: "Шпицберген и Ян Майен",
  SK: "Словакия",
  SL: "Сьерра-Леоне",
  SM: "Сан-Марино",
  SN: "Сенегал",
  SO: "Сомали",
  SR: "Суринам",
  SS: "Южный Судан",
  ST: "Сан-Томе и Принсипи",
  SV: "Эль-Сальвадор",
  SX: "Синт-Мартен",
  SY: "Сирийская Арабская Республика",
  SZ: "Свазиленд",
  TA: "Тристан-да-Кунья",
  TC: "Острова Теркс и Кайкос",
  TD: "Чад",
  TG: "Того",
  TH: "Таиланд",
  TJ: "Таджикистан",
  TK: "Токелау",
  TL: "Тимор-Лесте",
  TM: "Туркмения",
  TN: "Тунис",
  TO: "Тонга",
  TR: "Турция",
  TT: "Тринидад и Тобаго",
  TV: "Тувалу",
  TW: "Тайвань (Китай)",
  TZ: "Танзания, Объединенная Республика",
  UA: "Украина",
  UG: "Уганда",
  US: "Соединенные Штаты",
  UY: "Уругвай",
  UZ: "Узбекистан",
  VA: "Папский Престол (Государство &mdash; город Ватикан)",
  VC: "Сент-Винсент и Гренадины",
  VE: "Венесуэла Боливарианская Республика",
  VG: "Виргинские острова, Британские",
  VI: "Виргинские острова, США",
  VN: "Вьетнам",
  VU: "Вануату",
  WF: "Уоллис и Футуна",
  WS: "Самоа",
  XK: "Косово",
  YE: "Йемен",
  YT: "Майотта",
  ZA: "Южная Африка",
  ZM: "Замбия",
  ZW: "Зимбабве",
};

const countries = Object.keys(uk).filter(
  (key) => key.length === 2 && key !== "RU" && key !== "BY"
);

export default { uk, ru, countries };
