import { useRouter } from "next/router"
import Hero from "./sections/hero"
import It from "./sections/it"
import Directions from "./sections/directions"
import MarkTextList from "./sections/markTextList"
import VideoList from "./sections/videoList"
import Next from "./sections/next"
import Profession from "./sections/profession"
import HowItWorks from "./sections/howItWorks"
import LeadForm from "./sections/lead-form"
import Quiz from "./sections/quiz"

// Map Strapi sections to section components
const sectionComponents = {
  "sections.hero": Hero,
  "sections.it": It,
  "sections.directions": Directions,
  "sections.course": MarkTextList,
  "sections.video-list": VideoList,
  "sections.next": Next,
  "sections.profession": Profession,
  "sections.how-it-works": HowItWorks,
  "sections.lead-form": LeadForm,
  "sections.quiz": Quiz,
}

// Display a section individually
const Section = ({ sectionData }) => {
  const router = useRouter()

  const openRegisterModal = () => {
    router.push("/quiz")
  }

  // Prepare the component
  const SectionComponent = sectionComponents[sectionData.__component]

  if (!SectionComponent) {
    return null
  }

  // Display the section
  return (
    <SectionComponent data={sectionData} openRegister={openRegisterModal} />
  )
}

const PreviewModeBanner = () => {
  const router = useRouter()
  const exitURL = `/api/exit-preview?redirect=${encodeURIComponent(
    router.asPath
  )}`

  return (
    <div className="bg-red-600 py-4 font-semibold uppercase tracking-wide text-red-100">
      <div className="container">
        Preview mode is on.{" "}
        <a
          className="underline"
          href={`/api/exit-preview?redirect=${router.asPath}`}
        >
          Turn off
        </a>
      </div>
    </div>
  )
}

// Display the list of sections
const Sections = ({ sections, preview }) => {
  return (
    <div className="flex flex-col">
      {/* Show a banner if preview mode is on */}
      {preview && <PreviewModeBanner />}
      {/* Show the actual sections */}
      {sections.map((section) => (
        <Section
          sectionData={section}
          key={`${section.__component}${section.id}`}
        />
      ))}
    </div>
  )
}

export default Sections
