import { useEffect, useState, useRef } from "react"
import { useRouter } from "next/router"
import PropTypes from "prop-types"
import Link from "next/link"

import Cookies from "js-cookie"
import { MdExpandMore } from "react-icons/md"
import WorldIcon from "./icons/world"

import { useOnClickOutside } from "../utils/hooks"
import { getLocalizedPage } from "../utils/localize"
import { localizePath } from "../utils/localize"
import classNames from "classnames"

const LocaleSwitch = ({ pageContext }) => {
  const isMounted = useRef(false)
  const select = useRef()
  const router = useRouter()
  const [locale, setLocale] = useState()
  const [showing, setShowing] = useState(false)

  const { slug } = pageContext

  const color = [{ "text-black": slug }, { "text-white": !slug }]

  const handleLocaleChange = async (selectedLocale) => {
    // Persist the user's language preference
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    Cookies.set("NEXT_LOCALE", selectedLocale)
    setLocale(selectedLocale)
  }

  const handleLocaleChangeRef = useRef(handleLocaleChange)
  useOnClickOutside(select, () => setShowing(false))

  useEffect(() => {
    const localeCookie = Cookies.get("NEXT_LOCALE")
    if (!localeCookie) {
      handleLocaleChangeRef.current(router.locale)
    }

    const checkLocaleMismatch = async () => {
      if (
        !isMounted.current &&
        localeCookie &&
        localeCookie !== pageContext.locale
      ) {
        // Redirect to locale page if locale mismatch
        const localePage = getLocalizedPage(localeCookie, pageContext)

        router.push(
          `${localizePath({ ...pageContext, ...localePage })}`,
          `${localizePath({ ...pageContext, ...localePage })}`,
          { locale: localePage.locale }
        )
      }
      setShowing(false)
    }

    setLocale(localeCookie || router.locale)
    checkLocaleMismatch()

    return () => {
      isMounted.current = true
    }
  }, [locale, router, pageContext])

  return (
    <div ref={select} className="relative">
      <button
        type="button"
        className="hover:bg-primary-50 focus:bg-primary-50 flex h-full w-20 cursor-pointer items-center justify-between rounded-md px-2 py-2 hover:text-primary-600 focus:text-primary-600"
        onClick={() => setShowing(!showing)}
      >
        <WorldIcon pathPage={slug} />
        <span className={classNames("font-medium uppercase", ...color)}>
          {router.locale === "uk" ? "ua" : router.locale}
        </span>
        <MdExpandMore className={classNames("ml-1", ...color)} />
      </button>
      <div
        className={`z-10 mt-1 w-full rounded-md bg-white p-1 shadow-lg ${
          showing ? "absolute" : "hidden"
        }`}
      >
        {pageContext.localizedPaths &&
          pageContext.localizedPaths.map(({ href, locale }) => {
            return (
              <Link
                href={href}
                key={locale}
                locale={locale}
                role="option"
                passHref
              >
                <a
                  onClick={() => handleLocaleChange(locale)}
                  className="hover:bg-primary-50 block cursor-pointer rounded-md p-2 text-center uppercase hover:text-primary-600"
                >
                  {locale === "uk" ? "ua" : locale}
                </a>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

LocaleSwitch.propTypes = {
  initialLocale: PropTypes.string,
}

export default LocaleSwitch
