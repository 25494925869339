import RichText from "../elements/rich-text"

const Profession = ({ data }) => {
  return (
    <section className="section">
      <div className="container text-center">
        <h2 className="section-title">{data.title}</h2>
        {data.subtitle && <p className="mb-8">{data.subtitle}</p>}
        <div className="rounded-xl bg-white py-8 px-16">
          <RichText content={data.description} />
        </div>
      </div>
    </section>
  )
}

export default Profession
