import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import axios from "axios"
import Swal from "sweetalert2"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { loginUser } from "../../redux/userSlice"
import { Formik, Form, Field } from "formik"
import * as yup from "yup"
import * as Sentry from "@sentry/nextjs"

import Button from "../elements/button"
import Loader from "../elements/loader"
import { getButtonAppearance } from "utils/button"

const Login = ({ data }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation(["form", "common"])

  const user = useSelector((state) => state.user, shallowEqual)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user.token) {
      router.push("/cabinet")
    }
  }, [])

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email(t("emailValidation"))
      .required(t("requiredValidation")),
    password: yup
      .string()
      .min(8, t("passwordMinLength"))
      .max(20, t("passwordMaxLength"))
      .required(t("requiredValidation")),
  })

  const initialValuesForm = {
    email: "",
    password: "",
  }

  return (
    <section className="section loginForm" id="loginForm">
      <div className="container">
        <h1 className="registration-title">{data.title}</h1>
        {!user.token ? (
          <Formik
            initialValues={initialValuesForm}
            validateOnBlur
            validationSchema={LoginSchema}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, resetForm }
            ) => {
              const { email, password } = values

              setLoading(true)
              try {
                setErrors({ api: null })
                await axios
                  .post("/api/auth/login/", {
                    email,
                    password,
                  })
                  .then((res) => {
                    resetForm()

                    dispatch(
                      loginUser({ token: res.data.token, ...res.data.user })
                    )

                    let timerInterval
                    Swal.fire({
                      icon: "success",
                      title: t("success", { ns: "common" }),
                      text: t("redirect", { ns: "common" }),
                      timer: 1000,
                      timerProgressBar: true,
                      didOpen: () => {
                        Swal.showLoading()
                      },
                      willClose: () => {
                        clearInterval(timerInterval)
                      },
                    }).then((result) => {
                      router.push("/cabinet")
                    })
                  })
                  .catch((err) => {
                    if (err.response.status === 401) {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: t("wrongCredentials"),
                      })
                    }

                    setErrors({ api: err.message })
                    console.log(err.message)
                  })
              } catch (err) {
                setErrors({ api: err.message })
                console.log(err.message)

                Sentry.captureException(err)

                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: t("error", { ns: "common" }),
                })
              } finally {
                setSubmitting(false)
                setLoading(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => (
              <div className="form__wrapper bg-gray-800 px-8 py-20 text-white md:px-16">
                <Form className="form">
                  <div className="form__group">
                    <Field
                      className="form__input rounded-sm bg-white"
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      placeholder=" "
                    />
                    <label className="form__label" htmlFor="email">
                      Email
                    </label>
                    {errors.email && touched.email && (
                      <p className="form__message">{errors.email}</p>
                    )}
                  </div>

                  <div className="form__group">
                    <Field
                      className="form__input rounded-sm bg-white"
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="current-password"
                      placeholder=" "
                    />
                    <label className="form__label" htmlFor="password">
                      {t("passwordLabel") || "Password"}
                    </label>
                    {errors.password && touched.password && (
                      <p className="form__message">{errors.password}</p>
                    )}
                  </div>

                  {loading && (
                    <div className="loader__wrapper">
                      <Loader />
                    </div>
                  )}
                  <Button
                    type="submit"
                    button={{ text: t("submitLogin") }}
                    appearance={getButtonAppearance("primary", "light")}
                    disabled={isSubmitting}
                    styles="mx-auto w-full"
                  />
                </Form>
              </div>
            )}
          </Formik>
        ) : (
          <p className="text-center text-2xl">
            {t("redirect", { ns: "common" })}
          </p>
        )}
      </div>
    </section>
  )
}

export default Login
