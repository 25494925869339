import classNames from "classnames"
import PropTypes from "prop-types"
import { buttonLinkPropTypes } from "utils/types"
import Loader from "./loader"

const Button = ({
  button,
  appearance,
  compact = false,
  handleClick,
  loading = false,
  styles,
  type,
  stylesText,
}) => {
  return (
    <button
      className={classNames("block", styles)}
      onClick={handleClick}
      type={type}
    >
      <span
        className={classNames(
          // Common classes
          "easy-linear flex w-full justify-center rounded-2xl border text-center font-semibold tracking-wide duration-300 md:w-auto",
          stylesText,
          // Full-size button
          {
            "px-[20px] py-[24px] text-[18px] font-bold leading-[1.33] lg:text-[20px]":
              compact === false,
          },
          // Compact button
          {
            "px-6 py-4 text-xs": compact === true,
          },
          // Specific to when the button is fully dark
          {
            "border-accent bg-accent text-white hover:bg-transparent hover:text-accent":
              appearance === "dark",
          },
          // Specific to when the button is dark outlines
          {
            "border-accent text-accent hover:border-white hover:bg-accent hover:text-white":
              appearance === "dark-outline",
          },
          // Specific to when the button is fully white
          {
            "border-accent bg-white text-accent hover:border-white hover:bg-accent hover:text-white":
              appearance === "white",
          },
          // Specific to when the button is white outlines
          {
            "border-white text-white hover:border-accent hover:text-accent":
              appearance === "white-outline",
          }
        )}
      >
        {loading && <Loader />}
        {button.text}
      </span>
    </button>
  )
}

Button.propTypes = {
  button: buttonLinkPropTypes,
  appearance: PropTypes.oneOf([
    "dark",
    "white-outline",
    "white",
    "dark-outline",
  ]),
  compact: PropTypes.bool,
}

export default Button
