import Login from "./layouts/login"
import Register from "./elements/register"
import Cabinet from "./layouts/cabinet"
import Verify from "./layouts/verify"

const layoutComponents = {
  "layout.login": Login,
  "layout.register": Register,
  "layout.cabinet": Cabinet,
  "layout.verify": Verify,
}

// Display a layout individually
const Layout = ({ layoutData }) => {
  // Prepare the component
  const LayoutComponent = layoutComponents[layoutData.__component]

  if (!LayoutComponent) {
    return null
  }

  // Display the layout
  return <LayoutComponent data={layoutData} />
}

// Display the list of layouts
const Layouts = ({ layouts }) => {
  return (
    <div className="flex flex-col">
      {/* Show the actual layouts */}
      {layouts.map((layout) => (
        <Layout layoutData={layout} key={`${layout.__component}${layout.id}`} />
      ))}
    </div>
  )
}

export default Layouts
