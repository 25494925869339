import RichText from "@/components/elements/rich-text"
import ButtonLink from "@/components/elements/button-link"
import classNames from "classnames"
import { getButtonAppearance } from "../../utils/button"
import NextImage from "@/components/elements/image"
import { useMediaQuery } from "react-responsive"

export const QuizSingleResult = ({ result }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 975px)" })
  const { title, image, image_mobile, btn, text, project } = result
  const { subtitle: prjSubtitle, description: prjDescription } = project
  const correctImage = !isTabletOrMobile ? image : image_mobile

  return (
    <div className="pb-3">
      {title && (
        <RichText
          style={
            "mb-[48px] text-center text-[28px] leading-[34px] lg:mb-0 max-w-[320px] lg:max-w-none mx-auto lg:text-[40px] lg:leading-[1.3]"
          }
          content={title}
        />
      )}
      <div className="md:flex md:items-center md:justify-between">
        <div className="">
          {text && (
            <RichText
              style={
                "mb-6 text-center text-[20px] leading-[24px] max-w-[320px] mx-auto"
              }
              content={text}
            />
          )}
          {btn && (
            <ButtonLink
              style={classNames("")}
              button={btn}
              appearance={getButtonAppearance(btn.type, "light")}
              stylesText="px-[32px] py-[16px] mb-8 max-w-[240px] mx-auto"
            />
          )}
        </div>

        <div className="relative mx-auto max-w-[288px] py-[30px] md:mx-0 md:min-w-[288px] lg:min-w-[490px] lg:pb-0 xl:min-w-[670px]">
          {prjDescription && (
            <RichText
              style={
                "text-[16px] leading-[20px] max-w-[190px] absolute top-0 left-0 xl:max-w-[250px] xl:top-[44px] lg:top-[30px]"
              }
              content={prjDescription}
            />
          )}
          <div className="md:min-w-[100%]">
            <NextImage media={correctImage} />
          </div>
          {prjSubtitle && (
            <RichText
              style={
                "text-[10px] leading-[12px] max-w-[100px] absolute bottom-[5px] left-0 xl:bottom-[100px] lg:bottom-[75px]"
              }
              content={prjSubtitle}
            />
          )}
        </div>
      </div>
    </div>
  )
}
