import NextImage from "../elements/image"

const Directions = ({ data }) => {
  return (
    <section className="directions section">
      <div className="container">
        <h2 className="mb-[27px] text-center text-[32px] font-bold leading-[1.2] lg:text-[42px] xl:mb-[60px] xl:text-[48px]">
          {data.title}
        </h2>

        <ul className="directions-list mb-[24px] grid list-none grid-cols-1 gap-y-[28px] md:grid-cols-2 xl:mb-[60px] xl:grid-cols-4">
          {data.list.map((item) => (
            <li
              className="relative mx-auto h-[280px] w-[280px] flex-col rounded-[16px] bg-gray-500 pt-[20px]"
              key={item.id}
            >
              <div className="h-[280px] w-[280px]">
                <h3 className="text-center text-[24px] font-bold leading-[1.2] text-white">
                  {item.text}
                </h3>
                <div className="absolute inset-0 ">
                  <NextImage media={item.image} width="280px" height="280px" />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Directions
