import classNames from "classnames"
import { getButtonAppearance } from "utils/button"
import Button from "../elements/button"
import NextImage from "../elements/image"
import RichText from "../elements/rich-text"

const HowItWorks = ({ data, openRegister }) => {
  return (
    <section className="section">
      <div className="container">
        <h2 className="mb-7 text-center text-3xl font-bold">{data.title}</h2>
        <div className="mb-5 md:mb-10 md:flex md:justify-between xl:mb-20 xl:justify-around">
          <div className="mb-8 md:mb-0 md:mr-4 lg:mr-8 xl:mr-0">
            <h3 className="mb-5 text-center text-2xl font-bold md:text-[16px] lg:text-xl xl:text-2xl">
              {data.imageList.title}
            </h3>
            <ul className="flex list-none flex-col ">
              {data.imageList.image.map((item) => (
                <li key={item.id} className="m-auto mb-3 last:mb-0">
                  <NextImage
                    media={item.image}
                    width="272px"
                    height="152px"
                    alt={item.image.alternativeText}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-10 md:mb-0 md:mr-3 md:pt-11 lg:mr-8 xl:mr-0">
            <ul className="flex flex-col justify-between last:mb-0">
              {data.markdownList.map((item, index) => (
                <li
                  key={item.id}
                  className="m-auto mb-7 max-w-[280px] last:mb-0 md:max-w-[350px]  lg:mb-28 lg:max-w-[300px] xl:max-w-[430px]"
                >
                  {item.title && (
                    <h3 className="mb-5 text-xl font-bold md:mb-4 md:text-[16px] lg:text-xl xl:text-2xl">
                      {item.title}
                    </h3>
                  )}
                  {item.text && (
                    <RichText
                      style={classNames(
                        "md:text-xs md:mb-3 lg:mb-0 xl:text-2xl xl:font-medium lg:text-xl"
                      )}
                      content={item.text}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-center  md:mb-0 lg:items-stretch">
            <h3 className="mb-7 text-center text-xl font-bold md:mb-5 md:min-w-[222px] md:text-[16px] lg:text-xl xl:text-2xl">
              {data.imageLink.title}
            </h3>

            <NextImage
              media={data.imageLink.image}
              width="280px"
              height="474px"
              alt={data.imageLink.title}
            />
          </div>
        </div>

        <Button
          stylesText="px-1 text-[18px]"
          styles="mx-auto w-[100%] max-w-[370px] "
          button={data.btn}
          appearance={getButtonAppearance(data.btn.type, "light")}
          key={data.btn.id}
          handleClick={openRegister}
          type="button"
        />
      </div>
    </section>
  )
}

export default HowItWorks
