import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import axios from "axios"

import ErrorPage from "next/error"
import { getPageData, fetchAPI, getGlobalData } from "utils/api"
import Sections from "@/components/sections"
import Layouts from "@/components/layouts"
import Seo from "@/components/elements/seo"
import Layout from "@/components/layout"
import { getLocalizedPaths } from "utils/localize"
import { getAxiosConfig } from "../utils/helpers"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { logoutUser } from "../redux/userSlice"

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes

const DynamicPage = ({
  sections,
  layouts,
  metadata,
  preview,
  global,
  pageContext,
  slug,
  visibility,
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const [authorized, setAuthorized] = useState(false)

  const routerSlug = router.query.slug ? router.query.slug[0] : ""
  const layout = ["login", "register", "cabinet", "verify"]
  const user = useSelector((state) => state.user, shallowEqual)

  useEffect(async () => {
    const onError = () => {
      dispatch(logoutUser())
      setAuthorized(false)
      router.push("/login")
    }

    if (visibility === "private") {
      if (user.token) {
        await axios
          .get("/api/auth/check/", getAxiosConfig(user.token))
          .then((data) => {
            if (data.status === 200) {
              setAuthorized(true)
            } else onError()
          })
          .catch((err) => {
            console.log(err.message)
            onError()
          })
      } else onError()
    }
  }, [visibility])

  // useEffect(() => {
  //   if (user.token) {
  //     router.push("/cabinet")
  //   }
  // }, [user.token])

  // Check if the required data was provided
  if (!layout.includes(routerSlug) && !router.isFallback && !sections?.length) {
    return <ErrorPage statusCode={404} />
  }

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>
  }

  if (!user && visibility === "private") {
    return <div className="container">Redirecting...</div>
  }

  return (
    <Layout global={global} pageContext={pageContext}>
      {(visibility === "public" || authorized) && (
        <>
          {/* Add meta tags for SEO*/}
          <Seo metadata={metadata} />

          {!layouts?.length !== 0 && <Layouts layouts={layouts} />}

          {/* Display content sections */}
          {!sections?.length !== 0 && (
            <Sections sections={sections} preview={preview} />
          )}
        </>
      )}
    </Layout>
  )
}

export async function getStaticPaths(context) {
  // Get all pages from Strapi
  const allPages = context.locales.map(async (locale) => {
    const localePages = await fetchAPI(`/pages?_locale=${locale}`)
    return localePages
  })

  const pages = await (await Promise.all(allPages)).flat()

  const paths = pages.map((page) => {
    // Decompose the slug that was saved in Strapi
    const slugArray = !page.slug ? false : page.slug.split("/")

    return {
      params: { slug: slugArray },
      // Specify the locale to render
      locale: page.locale,
    }
  })

  return { paths, fallback: true }
}

export async function getStaticProps(context) {
  const { params, locale, locales, defaultLocale, preview = null } = context

  const globalLocale = await getGlobalData(locale)
  // Fetch pages. Include drafts if preview mode is on
  const pageData = await getPageData(
    { slug: !params.slug ? [""] : params.slug.slice(0, 1) },
    locale,
    preview,
    { visibility: params.visibility }
  )

  if (pageData == null) {
    // Giving the page no props will trigger a 404 page
    return { props: {} }
  }

  // We have the required page data, pass it to the page component
  const {
    contentSections,
    contentLayouts,
    metadata,
    localizations,
    slug,
    visibility,
  } = pageData

  const pageContext = {
    locale: pageData.locale,
    locales,
    defaultLocale,
    slug,
    visibility,
    localizations,
  }

  const localizedPaths = getLocalizedPaths(pageContext)

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        "common",
        "profile-menu",
        "form",
        "quiz",
      ])),
      preview,
      sections: contentSections,
      layouts: contentLayouts,
      metadata,
      global: globalLocale,
      pageContext: {
        ...pageContext,
        localizedPaths,
      },
      slug,
      visibility,
    },
  }
}

export default DynamicPage
