import { QuizDoubleResults } from "@/components/elements/QuizDoubleResults"
import { QuizSingleResult } from "@/components/elements/QuizSingleResult"
import { QuizMultipleResults } from "@/components/elements/QuizMultipleResults"

export const QuizResults = ({ results, data }) => {
  if (results.length === 1) {
    const result = results[0]

    return <QuizSingleResult result={result} />
  }

  if (results.length === 2) {
    const double = data.result.find((item) => item.type === "Double")
    const leftRes = results[0]
    const rightRes = results[1]

    return (
      <QuizDoubleResults
        double={double}
        leftRes={leftRes}
        rightRes={rightRes}
      />
    )
  }

  const multiple = data.result.find((item) => item.type === "Default")
  return <QuizMultipleResults multiple={multiple} />
}
