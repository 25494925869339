import classNames from "classnames"
import NextImage from "../elements/image"

const It = ({ data }) => {
  return (
    <section className="it section">
      <div className="container py-[40px] lg:pb-[90px] xl:pt-[80px] xl:pb-[130px]">
        <h2 className="before: mb-[24px] text-center text-[32px] font-bold leading-[1.2] lg:mb-[50px] lg:text-[42px] xl:mb-[60px] xl:text-[48px]">
          {data.title}
        </h2>

        <ul className="grid- grid list-none grid-cols-2 gap-y-[40px] md:grid-cols-3 xl:grid-cols-6 ">
          {data.itList.map((item) => (
            <li key={item.id} className="flex items-center justify-center">
              <div className="relative h-[150px] w-[80px] text-center lg:h-[180px] lg:w-[100px] xl:h-[225px] xl:w-[120px] ">
                <div
                  className={classNames(
                    `absolute bottom-[80px] left-[0px] h-[60px] w-[60px] rounded-full   lg:bottom-[94px] lg:h-[75px] lg:w-[75px] xl:bottom-[127px] xl:left-[3px] xl:h-[85px] xl:w-[85px]`,
                    {
                      "bg-pink-400": item.color === "bg-pink-400",
                      "bg-cyan": item.color === "bg-cyan-700",
                      "bg-indigo-500": item.color === "bg-indigo-500",
                      "bg-red-600": item.color === "bg-red-600",
                      "bg-green-600": item.color === "bg-green-600",
                      "bg-purple-500": item.color === "bg-purple-500",
                    }
                  )}
                ></div>
                <NextImage media={item.image} width="96px" height="96px" />
                <p className=" text-center text-[16px] font-medium leading-[1.25] lg:text-[20px] xl:mt-[16px] xl:text-[24px] ">
                  {item.text}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
export default It
