import PropTypes from "prop-types"
import Markdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import classNames from "classnames"
import Link from "./custom-link"
import { nanoid } from "nanoid"

const RichText = ({ content, style = "" }) => {
  return (
    <>
      <Markdown
        className={classNames("rich-text", style)}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: (props) => (
            <Link link={{ url: props.href, newTab: true, id: nanoid() }}>
              {props.children[0]}
            </Link>
          ),
        }}
      >
        {content}
      </Markdown>
    </>
  )
}

RichText.propTypes = {
  content: PropTypes.string,
}

export default RichText
