import axios from "axios"
import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Swal from "sweetalert2"

const Verify = ({ data }) => {
  const router = useRouter()
  const [routerSlug, routerId] = router.query.slug
  const [verified, setVerified] = useState("")
  const { t } = useTranslation("common")

  useEffect(async () => {
    if (routerId) {
      try {
        const user = await axios.get("/api/auth/verify/" + routerId)
        if (user) {
          setVerified(t("successfullyVerified"))

          let timerInterval
          Swal.fire({
            icon: "success",
            title: t("success"),
            text: verified,
            timer: 2000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
            },
            willClose: () => {
              clearInterval(timerInterval)
            },
          }).then((result) => {
            router.push("/login")
          })
        }
      } catch (error) {
        if (error.response.status === 404) {
          setVerified(t("userNotFound"))
          setTimeout(() => {
            router.push("/")
          }, 2000)
        } else {
          console.log(error)
        }
      }
    } else {
      router.push("/login")
    }
  }, [routerId])

  return (
    <div className="container">
      <h2 className="title">{data.title}</h2>
      {verified && <p>{verified}</p>}
    </div>
  )
}

export default Verify
