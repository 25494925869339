import PropTypes from "prop-types"
import { MdClose } from "react-icons/md"

import { useLockBodyScroll } from "utils/hooks"
import Logo from "./logo"
import Link from "next/link"
import { useRouter } from "next/router"
import { shallowEqual, useSelector } from "react-redux"
import { useTranslation } from "next-i18next"
import { useDispatch } from "react-redux"
import { logoutUser } from "../../redux/userSlice"
import classNames from "classnames"

const MobileNavMenu = ({ pagePath, closeSelf }) => {
  // Prevent window scroll while mobile nav menu is open
  useLockBodyScroll()

  const dispatch = useDispatch()
  const router = useRouter()
  const { asPath } = router

  const user = useSelector((state) => state.user, shallowEqual)
  const { t } = useTranslation("profile-menu")
  const logoutHandler = () => {
    router.push("/login")
    dispatch(logoutUser())
    closeSelf()
  }

  return (
    <div
      className={`pb-6" fixed top-0 left-0 z-10 h-screen w-screen overflow-y-scroll ${
        pagePath ? "bg-hero" : "bg-white"
      }`}
    >
      <div className="container flex h-full flex-col ">
        {/* Top section */}
        <div className="flex flex-row items-center justify-between py-4">
          {/* Company logo */}
          <Link href="/">
            <a aria-label="home">
              <Logo
                color={classNames({
                  "text-white": !pagePath,
                })}
              />
            </a>
          </Link>
          {/* Close button */}
          <button onClick={closeSelf} className="py-1 px-1">
            <MdClose
              aria-label={t("mobileMenuClose")}
              className={`h-8 w-auto  ${
                pagePath ? "text-white" : "text-black"
              }`}
            />
          </button>
        </div>
        {/* Bottom section */}
        <div className=" pt-9">
          {!user.token ? (
            <div className="flex flex-col items-center justify-center">
              {asPath !== "/login" && (
                <Link href="/login">
                  <a
                    className={`mb-8 text-3xl font-bold ${
                      pagePath ? "text-white" : "text-accent"
                    }`}
                    onClick={closeSelf}
                  >
                    {t("login")}
                  </a>
                </Link>
              )}
              {asPath !== "/register" && (
                <Link href="/#register">
                  <a
                    className={`text-3xl font-bold  ${
                      pagePath ? "text-white" : "text-accent"
                    }`}
                    onClick={closeSelf}
                  >
                    {t("register")}
                  </a>
                </Link>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              {asPath !== "/cabinet" && (
                <Link href="/cabinet">
                  <a
                    className={`mb-8 text-3xl font-bold ${
                      pagePath ? "text-white" : "text-accent"
                    }`}
                    onClick={closeSelf}
                  >
                    {t("cabinet")}
                  </a>
                </Link>
              )}
              <p
                onClick={logoutHandler}
                className={`text-3xl font-bold ${
                  pagePath ? "text-white" : "text-accent"
                }`}
              >
                {t("logout")}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MobileNavMenu.propTypes = {
  closeSelf: PropTypes.func,
}

export default MobileNavMenu
