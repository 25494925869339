import classNames from "classnames"
import Button from "../elements/button"
import { getButtonAppearance } from "../../utils/button"
import RichText from "../elements/rich-text"
import { useState } from "react"
import ButtonLink from "../elements/button-link"
import { useRouter } from "next/router"

const Next = ({ data }) => {
  const router = useRouter()
  const [customLength, setCustomLength] = useState(2)

  const listStyles =
    data.list.reduce(
      (allStyles, value) =>
        allStyles.concat(value.style ? [value.style.split(" ")] : ""),
      []
    ) || []

  return (
    <section className="section">
      <div className="container pt-[20px] pb-[40px]">
        {data.title && (
          <h2 className="mb-[24px] text-center text-[32px] font-bold">
            {data.title}
          </h2>
        )}
        {data.description && (
          <p className="mb-[20px] text-center text-[16px] font-medium leading-[1.25] xl:mb-[60px] xl:text-[38px] xl:leading-[1.2]">
            {data.description}
          </p>
        )}

        {data.list.length && (
          <ul
            className={classNames(
              "grid list-none grid-cols-1 items-start gap-8 text-white md:grid-cols-3 xl:mb-[60px]",
              { "mb-8": data.btn }
            )}
          >
            {data.list.slice(0, customLength).map((item, index) => (
              <li
                className={classNames(
                  "ar-1 flex flex-col justify-between rounded-xl p-2 shadow-lg md:h-[100%] md:w-[100%] xl:p-4",
                  {
                    "md:col-span-1": listStyles[index].includes("col-span-1"),
                    "ar-2 bg-list md:col-span-2":
                      listStyles[index].includes("col-span-2"),
                    "ar-3 md:col-span-3":
                      listStyles[index].includes("col-span-3"),
                  },
                  {
                    "bg-yellow-300": item.color === "yellow",
                    "bg-pink-400": item.color === "pink",
                    "bg-blue-800": item.color === "cyan",
                    "bg-blue-500": item.color === "indigo",
                    "bg-red-600": item.color === "red",
                    "bg-green-600": item.color === "green",
                    "bg-purple-500": item.color === "purple",
                    "bg-gray-500": item.color === "gray",
                    "bg-greenDark": item.color === "greendark",
                    "bg-greenLight": item.color === "greenlight",
                    "bg-orange": item.color === "orange",
                    "bg-lilac": item.color === "lilac",
                    "bg-blueDark": item.color === "blueDark",
                  }
                )}
                key={item.id}
              >
                <div className="flex items-start justify-between lg:mb-1 xl:mb-16">
                  {item.duration && (
                    <ul className="list-none text-xs font-light md:mb-4 lg:mb-1">
                      {/* {item.startDate && (
                        <li className="rocket px-1 py-0.5 pl-[18px] md:text-[12px] md:font-medium md:leading-[112%]">
                          {new Date(item.startDate).toLocaleDateString(
                            "uk-UA",
                            {
                              day: "numeric",
                              month: "long",
                            }
                          ) || ""}
                        </li>
                      )} */}
                      {item.duration && (
                        <li className="calendar mt-1 px-1 py-0.5 pl-[18px] md:text-[12px] md:font-medium md:leading-[112%]">
                          {item.duration}
                        </li>
                      )}
                    </ul>
                  )}
                  {(item.courseFormat || item.isBestseller) && (
                    <ul className="list-none text-center text-xs font-light">
                      {item.courseFormat && (
                        <li className="rounded-xl bg-gray-300 bg-opacity-20 px-2 py-0.5 md:font-medium">
                          {item.courseFormat}
                        </li>
                      )}
                      {item.isBestseller && (
                        <li className="mt-1 rounded-lg bg-accent px-1.5 py-0.5 md:font-medium">
                          Bestseller
                        </li>
                      )}
                    </ul>
                  )}
                </div>
                {item.title && (
                  <h3 className="text-xl font-semibold md:mb-4 md:text-xl lg:mb-0 lg:text-xl xl:text-[32px] xl:leading-[1.2]">
                    {item.title}
                  </h3>
                )}
                {item.description && (
                  <RichText
                    style={classNames(
                      "md:text-xs md:mb-3 lg:mb-0 xl:text-[14px] xl:leading-[1.2] xl:w-[300px]",
                      {
                        "md:w-1/2": listStyles[index].includes("col-span-2"),
                        "md:w-1/3": listStyles[index].includes("col-span-3"),
                      }
                    )}
                    content={item.description}
                  />
                )}
                {item.btn && (
                  <ButtonLink
                    style={classNames("md:px-0 md:text-xs xl:text-xl ", {
                      "md:w-2/3": listStyles[index].includes("col-span-2"),
                      "md:w-1/3": listStyles[index].includes("col-span-3"),
                    })}
                    compact={true}
                    button={item.btn}
                    appearance={getButtonAppearance(item.btn.type, "dark")}
                    stylesText="md:px-0 md:text-xs xl:text-xl"
                  />
                )}
              </li>
            ))}
          </ul>
        )}
        {/* {data.btn && customLength < data.list.length && (
          <Button
            styles="mx-auto xl:w-[270px] "
            button={data.btn}
            appearance={getButtonAppearance(data.btn.type, "light")}
            handleClick={() => setCustomLength(data.list.length)}
          />
        )} */}

        <ButtonLink
          button={{
            url: `https://goit.ua/course/?lang=${router.locale}&utm_source=Gostart&utm_medium=other&utm_campaign=allcources`,
            newTab: true,
            text: data.btn.text,
            id: data.btn.id,
          }}
          appearance={getButtonAppearance(data.btn.type, "light")}
          style="block mx-auto max-w-md"
          key={data.btn.id}
          type="button"
        />
      </div>
    </section>
  )
}

export default Next
