import { getStrapiMedia } from "utils/media"
import Image from "next/image"
import PropTypes from "prop-types"
import { mediaPropTypes } from "utils/types"

const NextImage = ({ media, ...props }) => {
  const { url, alternativeText } = media

  const loader = ({ src }) => {
    return getStrapiMedia(src)
  }

  // The image has a fixed width and height
  if (props.width && props.height) {
    return (
      <Image
        className={props.styles || ""}
        loader={loader}
        unoptimized={true}
        src={url}
        alt={alternativeText || ""}
        {...props}
      />
    )
  }

  // The image is responsive
  return (
    <Image
      className={props.styles || ""}
      loader={loader}
      unoptimized={true}
      layout="responsive"
      width={props.width || media.width}
      height={props.height || media.height}
      objectFit="contain"
      src={url}
      alt={alternativeText || ""}
    />
  )
}

NextImage.propTypes = {
  media: mediaPropTypes.isRequired,
  className: PropTypes.string,
}

export default NextImage
