import { useState } from "react"
import Image from "../elements/image"
import Modal from "../elements/modal"
import YoutubeVideo from "../elements/youtubeVideo"
import classNames from "classnames"
import ButtonLink from "../elements/button-link"
import RichText from "../elements/rich-text"
import { getButtonAppearance } from "../../utils/button"

const VideoList = ({ data }) => {
  const [modal, setModal] = useState(false)
  const [video, setVideo] = useState({})

  const handleModal = (e, video) => {
    if (video && video?.url) {
      setVideo({ ...video, videoId: video.url.split("=")[1] })
      setModal(true)
    }
  }

  return (
    <section className="section">
      <div className="container text-left md:text-center">
        <h2 className="section-title">{data.title}</h2>
        <ul
          className={classNames(
            "grid list-none items-start gap-4 md:gap-8",
            {
              "grid-cols-2 md:mx-auto md:w-3/4": data.list.length <= 2,
            },
            {
              "grid-cols-2 md:grid-cols-3": data.list.length > 2,
            },
            {
              "mb-8 md:mb-16": data.button,
            }
          )}
        >
          {data.list.map((video) => (
            <li
              className="cursor-pointer overflow-hidden rounded-xl duration-200 ease-linear hover:scale-105 hover:transform"
              key={video.id}
              onClick={(e) => handleModal(e, video)}
              aria-label={video.title}
            >
              <Image media={video.image} />
            </li>
          ))}
        </ul>

        {data.button && (
          <ButtonLink
            style="inline-block "
            button={data.button}
            appearance={getButtonAppearance(data.button.type, "light")}
          />
        )}
      </div>
      <Modal show={modal} setShow={setModal} title={video.title}>
        <div className="flex flex-col gap-8">
          <YoutubeVideo id={video.videoId} />
          {video.button && (
            <ButtonLink
              type="button"
              key={video.button.id}
              style="block mx-auto max-w-md"
              button={video.button}
              appearance={getButtonAppearance(video.button.type, "light")}
            />
          )}
          {video.description && (
            <div>
              <RichText content={video.description} />
            </div>
          )}
        </div>
      </Modal>
    </section>
  )
}

export default VideoList
