import RichText from "@/components/elements/rich-text"
import classNames from "classnames"
import ButtonLink from "@/components/elements/button-link"
import { getButtonAppearance } from "../../utils/button"
import NextImage from "@/components/elements/image"
import { useMediaQuery } from "react-responsive"

export const QuizMultipleResults = ({ multiple }) => {
  const { btn, image, image_mobile, title, text } = multiple
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 975px)" })
  const correctImage = !isTabletOrMobile ? image : image_mobile

  return (
    <>
      {title && (
        <RichText
          style={classNames(
            "mx-auto max-w-[300px] text-center text-[20px] leading-[24px] lg:max-w-[670px] lg:text-[24px] lg:leading-[29px] mb-[48px] lg:mb-[64px]"
          )}
          content={title}
        />
      )}

      <div className="lg:flex lg:justify-between">
        <div className="mb-[32px] lg:mb-0 lg:max-w-[300px] xl:max-w-[400px]">
          {text && (
            <RichText
              style={classNames(
                "mx-auto mb-6 max-w-[300px] text-center text-[20px] lg:max-w-none"
              )}
              content={text}
            />
          )}
          <ButtonLink
            style={classNames("")}
            button={btn}
            appearance={getButtonAppearance(btn.type, "light")}
            stylesText="max-w-[232px] mx-auto px-[32px] py-[16px] lg:max-w-none"
          />
        </div>

        <div className="mx-auto max-w-[305px] lg:mx-0 lg:min-w-[500px] xl:min-w-[600px]">
          <NextImage media={correctImage} />
        </div>
      </div>
    </>
  )
}
