import Link from "../elements/custom-link"
import Image from "next/image"

const Footer = ({ data }) => {
  return (
    <footer className="bg-black py-10 text-footer md:flex md:items-center md:py-20">
      <div className="container">
        {data.linkList && (
          <ul className="list-none md:flex md:items-center md:justify-between">
            {data.linkList.map((item) => (
              <li key={item.id} className="mb-5 last:mb-0 md:mb-0">
                <Link link={item}>{item.text}</Link>
              </li>
            ))}

            <li>
              <a
                href="https://goweb.goit.ua/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <Image
                  src="/images/footer/goweb.svg"
                  alt="ГоуВеб студія"
                  width="32px"
                  height="28px"
                />
              </a>
            </li>
          </ul>
        )}
      </div>
    </footer>
  )
}

export default Footer
