import { useState } from "react"
import YouTube from "react-youtube"
import classNames from "classnames"
import Loader from "./loader"

const YoutubeVideo = ({ id }) => {
  const [loading, setLoading] = useState(true)

  const opts = {
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      iv_load_policy: 3,
      showsearch: 0,
    },
  }

  const onReadyHandler = (event) => {
    setLoading(false)
  }

  return (
    <>
      <div
        className={classNames("aspect-w-16 aspect-h-9 mx-auto w-full", {
          hidden: loading,
        })}
      >
        <YouTube
          videoId={id}
          opts={opts}
          onReady={onReadyHandler}
          iframeClassName="w-full h-full"
        />
      </div>
      {loading && (
        <div className="mx-auto grid h-48 w-48 place-items-center md:h-96 md:w-96">
          <Loader />
        </div>
      )}
    </>
  )
}

export default YoutubeVideo
