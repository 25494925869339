import Navbar from "./elements/navbar"
import { useRouter } from "next/router"
import Footer from "./layouts/footer"

const Layout = ({ children, global, pageContext }) => {
  const { footer } = global
  const { slug } = pageContext

  return (
    <div className="flex min-h-screen flex-col justify-between bg-main font-medium">
      <header className={`${!slug ? "bg-hero" : "bg-transparent"}`}>
        <Navbar pageContext={pageContext} />
      </header>
      <main>{children}</main>
      <Footer data={footer} />
    </div>
  )
}

export default Layout
