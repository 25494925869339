import { getButtonAppearance } from "utils/button"
import Button from "../elements/button"

const Hero = ({ data, openRegister }) => {
  return (
    <section className="hero xl:bg-px bg-hero bg-no-repeat md:bg-contain">
      <div className="container pt-[30px] pb-[80px] text-white xl:pb-[176px] xl:pt-[35px]">
        <div className="flex flex-col items-center md:w-6/12 md:items-start">
          <p className="hidden md:mb-[16px] md:block md:text-left md:text-[18px] md:leading-[1.2] lg:text-[24px]">
            {data.label}
          </p>
          <h1 className="mb-[40px] w-[280px] text-center text-[34px] font-bold leading-[1.31] md:text-left lg:w-[460px] lg:text-[65px] xl:text-[84px]">
            {data.title}
          </h1>
          <p className="mb-[40px] w-[280px] text-center text-[16px] font-medium leading-[1.25] md:w-[350px] md:text-left md:text-[18px] lg:w-[460px] lg:text-[24px]">
            {data.description}
          </p>
          <Button
            button={data.btn}
            appearance={getButtonAppearance(data.btn.type, "light")}
            key={data.btn.id}
            type="button"
            styles="w-[280px] md:w-[370px]"
            handleClick={openRegister}
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
