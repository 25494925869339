import { useRef, useState } from "react"
import classNames from "classnames"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { addAnswer, resetQuiz } from "../../redux/quizSlice"
import { incrementCounter, decrementCounter } from "../../redux/counterSlice"
import NextImage from "../elements/image"
import RichText from "../elements/rich-text"
import { useTranslation } from "next-i18next"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade } from "swiper"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import Button from "../elements/button"
import { getButtonAppearance } from "../../utils/button"
import { QuizResults } from "@/components/elements/QuizResults"
import QuizRegister from "@/components/elements/QuizRegister"
import { useEffect } from "react"

const Quiz = ({ data }) => {
  const dispatch = useDispatch()
  const quiz = useSelector((state) => state.quiz, shallowEqual)
  const user = useSelector((state) => state.user, shallowEqual)
  const [showSlider, setShowSlider] = useState(true)

  const { t } = useTranslation("quiz")
  const swiperRef = useRef()

  /* Setting the slider to the last question answered. */
  useEffect(() => {
    if (swiperRef.current) {
      const lastIndex = quiz?.counter?.value || quiz?.results.length
      swiperRef.current.slideTo(lastIndex, 100)
    }
  }, [])

  /* Setting the radio button to checked if the user has already answered the question. */
  useEffect(() => {
    if (quiz.results.length) {
      quiz.results.map((res) => {
        document.getElementById(res.answerId)?.setAttribute("checked", true)
      })
    }
  }, [])

  /* Checking if the quiz is finished and if the user is logged in. If so, it will hide the slider. */
  useEffect(() => {
    if (quiz.counter.value === data.questions.length) {
      setShowSlider(false)
    }
    user.token && setShowSlider(false)
  }, [user, quiz.counter.value, data])

  const next = () => {
    dispatch(incrementCounter())
    const { activeIndex, slides } = swiperRef.current
    if (activeIndex + 1 === slides.length) {
      setShowSlider(false)
    } else {
      swiperRef.current.slideNext()
    }
  }

  const previous = () => {
    dispatch(decrementCounter())
    swiperRef.current.slidePrev()
  }

  const getResults = () => {
    let results = Object.entries(
      quiz.results.reduce((acc, item) => {
        acc[item.type] = acc[item.type] ? acc[item.type] + 1 : 1
        return acc
      }, {})
    ).filter((item) => item[1] > 2)

    results = results.reduce((acc, res) => {
      const type = res[0]
      const newItem = data.result.find((res) => res.type === type)
      return [...acc, newItem]
    }, [])

    return results
  }

  return (
    <section className="section !py-0">
      <div className="container">
        {showSlider && quiz?.counter?.value !== data.questions.length && (
          <>
            {data.title && (
              <h1 className="mb-10 text-center text-5xl font-bold">
                {data.title}
              </h1>
            )}
            <Swiper
              modules={[EffectFade]}
              effect="fade"
              fadeEffect={{ crossFade: true }}
              keyboard={false}
              allowTouchMove={false}
              autoHeight={true}
              onSwiper={(swiper) => {
                swiperRef.current = swiper
              }}
            >
              {data.questions.map((item, loopIndex) => (
                <SwiperSlide key={item.id}>
                  <div className="md:grid md:gap-8 lg:grid-cols-2">
                    <div className="px-2">
                      <div className="quiz__body mb-8">
                        <div className="question__block">
                          <p className="mb-6 text-left text-2xl font-bold">
                            {t("question")}
                          </p>
                          <RichText
                            style={classNames(
                              "mb-10 lg:max-w-[470px] text-left"
                            )}
                            content={item.question}
                          />
                        </div>
                        <ul className="answer__list grid grid-cols-1 gap-8 md:grid-cols-2">
                          {item.anwers.map((answer, index) => (
                            <li key={answer.id}>
                              <label className="relative block pl-14 text-left">
                                <input
                                  className="answer__input visually-hidden"
                                  type="radio"
                                  id={`answer-${loopIndex}-${index}`}
                                  name={`answer-${loopIndex}`}
                                  value={answer.answer}
                                  onChange={() => {
                                    dispatch(
                                      addAnswer({
                                        type: answer.type,
                                        answerId: `answer-${--loopIndex}-${index}`,
                                        id: `question-${loopIndex}`,
                                      })
                                    )
                                    next()
                                  }}
                                />
                                <span
                                  className={classNames(
                                    "answer__checkbox z-2 absolute top-0 left-0 block h-8 w-8 cursor-pointer rounded-lg border-2 border-current",
                                    {
                                      "text-purple-500": index === 0,
                                      "text-yellow-500": index === 1,
                                      "text-pink-400": index === 2,
                                      "text-indigo-500": index === 3,
                                      "text-green-600": index === 4,
                                    }
                                  )}
                                ></span>
                                <span className="break-words">
                                  {answer.answer}
                                </span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="quiz__paging text-center">
                        <a
                          className="cursor-pointer"
                          aria-label="Previous slide"
                          onClick={previous}
                        >
                          <span>←</span> {t("previous")}
                        </a>
                        <span className="text-md mx-4">
                          {++loopIndex} / {data.questions.length}
                        </span>
                        <a
                          className="cursor-pointer"
                          aria-label="Next slide"
                          onClick={next}
                        >
                          {t("next")} <span>→</span>
                        </a>
                      </div>
                    </div>
                    <div className="max-h-[300px] md:max-h-[600px] lg:max-h-full">
                      <NextImage media={item.image} styles="swiper-lazy" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}

        {!showSlider && !user.token && <QuizRegister data={data.form} />}

        {!showSlider && user.token && (
          <QuizResults data={data} results={getResults()} />
        )}
      </div>
      {/*<Button*/}
      {/*  button={{ text: t("try_again") }}*/}
      {/*  appearance={getButtonAppearance("primary", "light")}*/}
      {/*  type="button"*/}
      {/*  styles="w-[280px] md:w-[370px] absolute top-0"*/}
      {/*  handleClick={() => {*/}
      {/*    dispatch(resetQuiz())*/}
      {/*    setShowSlider(true)*/}
      {/*  }}*/}
      {/*/>*/}
    </section>
  )
}

export default Quiz
