import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import axios from "axios"
import Cookies from "js-cookie"
import * as yup from "yup"
import { Formik, Form, Field } from "formik"
import Button from "./button"
import { getButtonAppearance } from "../../utils/button"
import Loader from "./loader"
import { useTranslation } from "next-i18next"
import Swal from "sweetalert2"
import * as Sentry from "@sentry/nextjs"

import "react-phone-number-input/style.css"
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import phoneLabels from "../../utils/phoneInputLabels"

import { useDispatch } from "react-redux"
import { loginUser } from "../../redux/userSlice"
import RichText from "@/components/elements/rich-text"

const Register = ({ data }) => {
  const router = useRouter()
  const { locale } = router
  const dispatch = useDispatch()
  const { t } = useTranslation(["form", "common", "profile-menu"])
  const { t: quizForm } = useTranslation(["quiz"])

  const [info, setInfo] = useState({ ip: "", country: "" })
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(false)

  // Get IP Address and Location URL
  const ipURL = "https://ip.nf/me.json"

  // UTM marks list
  const utm_marks = [
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
  ]

  // Set the cookies
  utm_marks.forEach((mark) => {
    const data = router.query[mark]
    if (data) {
      Cookies.set(mark, data)
    }
  })

  // GET user IP info
  useEffect(async () => {
    let isResponse = true
    await axios.get(ipURL).then((response) => {
      if (isResponse) {
        return setInfo({ ...response.data })
      }
    })

    return () => {
      isResponse = false
    }
  }, [])

  const LeadSchema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^.[a-zA-Zа-яА-ЯёЁЇїІіЄєҐґ ,.'ʼ`-]{1,19}$/gm,
        t("nameValidation")
      )
      .min(2, t("nameMinLength"))
      .max(20, t("nameMaxLength"))
      .required(t("requiredValidation")),
    phone: yup
      .string()
      .test("test-name", t("phoneValidation"), function (value) {
        if (!value) {
          return false
        }
        return isValidPhoneNumber(value)
      })
      .required(t("requiredValidation")),
    email: yup
      .string()
      .email(t("emailValidation"))
      .required(t("requiredValidation")),
    agreement: yup.boolean().oneOf([true], t("requiredValidation")),
  })

  const initialValuesForm = {
    name: "",
    phone: "",
    email: "",
    agreement: false,
  }

  return (
    <>
      <div className="form__wrapper relative pt-6 pb-20">
        {loading && (
          <div className="absolute inset-0 z-10 grid place-items-center bg-[#f5f5f5]">
            <Loader />
          </div>
        )}

        <>
          <Formik
            initialValues={initialValuesForm}
            validateOnBlur
            validationSchema={LeadSchema}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, resetForm }
            ) => {
              const { name, phone, email } = values

              setLoading(true)
              try {
                setErrors({ api: null })
                await axios
                  .post("/api/auth/register/", {
                    name: name,
                    phone: phone,
                    email: email,
                    info: info,
                    zoho: data.zoho,
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      dispatch(loginUser(res.data))
                      dataLayer.push({ event: "lead" })
                      setUser(true)
                    }
                  })
              } catch (err) {
                if (err.response.status === 409) {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("emailExists"),
                  })
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("error"),
                  })

                  Sentry.captureException(err)
                }
                setErrors({ api: err.message })
                console.log(err.message)
              } finally {
                setSubmitting(false)
                resetForm()
                setLoading(false)
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleBlur,
            }) => (
              <div className="form__inner">
                <RichText
                  style={"text-center text-[20px] leading-[1.2] mb-5 lg:mb-6"}
                  content={quizForm("quizFormTitle")}
                />
                <RichText
                  style={
                    "text-center text-[20px] leading-[1.2] mb-5 text-accent font-bold lg:mb-6"
                  }
                  content={quizForm("quizFormSubtitle")}
                />
                <Form className="flex flex-col gap-7">
                  <div className="form__group">
                    <label className="" htmlFor="name">
                      {t("nameLabel")}
                    </label>
                    <Field
                      className="form__input form__input--name mt-4"
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      placeholder=" "
                    />

                    {errors.name && touched.name && (
                      <p className="form__message">{errors.name}</p>
                    )}
                  </div>

                  <div className="form__group">
                    <label className="mb-4" htmlFor="name">
                      {t("phoneLabel")}
                    </label>
                    <PhoneInput
                      className="form__input form__input--phone mt-4 bg-white"
                      id="phone"
                      labels={phoneLabels[locale]}
                      value={values.phone}
                      international={true}
                      defaultCountry="UA"
                      countries={phoneLabels.countries}
                      withCountryCallingCode={true}
                      onChange={(value) => {
                        setFieldValue("phone", value)
                      }}
                      onBlur={handleBlur}
                    />

                    {errors.phone && touched.phone && (
                      <p className="form__message">{errors.phone}</p>
                    )}
                  </div>

                  <div className="form__group mb-2">
                    <label className="mb-4" htmlFor="email">
                      Email
                    </label>
                    <Field
                      className="form__input form__input--email mt-4"
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="off"
                      placeholder=" "
                    />

                    {errors.email && touched.email && (
                      <p className="form__message">{errors.email}</p>
                    )}
                  </div>

                  <Button
                    type="submit"
                    button={data.submitButton}
                    disabled={isSubmitting}
                    appearance={getButtonAppearance(
                      data.submitButton.type,
                      "light"
                    )}
                    style="form__btn"
                  />

                  <label className="relative flex items-start gap-2 text-[11px] leading-[1.27] text-[#504C5C]">
                    <Field
                      className="block h-8 w-8 md:h-4 md:w-4"
                      type="checkbox"
                      name="agreement"
                      id="agreement"
                      autoComplete="off"
                      placeholder=""
                    />
                    <span>
                      Подаючи цю заявку, я визнаю, що я усвідомлюю політику
                      конфіденційності та приймаю її умови, включаючи ті, що
                      стосуються регулювання обробки моїх особистих даних
                    </span>

                    {errors.agreement && touched.agreement && (
                      <p className="form__message">{errors.agreement}</p>
                    )}
                  </label>
                </Form>
              </div>
            )}
          </Formik>
        </>
      </div>
    </>
  )
}

export default Register
