import React from "react"
import classNames from "classnames"
import RichText from "../elements/rich-text"
import propTypes from "prop-types"
import ButtonLink from "../elements/button-link"
import { getButtonAppearance } from "utils/button"

const MarkTextList = ({ data }) => {
  const listStyles =
    data.itemList.reduce(
      (allStyles, value) =>
        allStyles.concat(value.style ? [value.style.split(" ")] : ""),
      []
    ) || []

  return (
    <section className="section">
      <div className="container">
        <h2
          className={classNames("section-title", {
            "text-center": data.description,
          })}
        >
          {data.title}
        </h2>

        {data.description && (
          <p className="section-description">{data.description}</p>
        )}

        <ul
          className={classNames(
            "markTextList grid list-none grid-cols-1 gap-8",
            {
              "md:grid-cols-2": data.itemList.length <= 2,
              "md:grid-cols-5": data.itemList.length > 2,
              "md:mb-10": data.button,
            }
          )}
        >
          {data.itemList.map((item, index) => (
            <li
              key={item.id}
              className={classNames(
                "relative p-4",
                {
                  "md:py-16": data.description,
                  "md:py-8 lg:py-16": !data.description,
                },
                {
                  "md:col-span-1": listStyles[index].includes("col-span-1"),
                  "md:col-span-2": listStyles[index].includes("col-span-2"),
                  "md:col-span-3": listStyles[index].includes("col-span-3"),
                  "md:col-span-4": listStyles[index].includes("col-span-4"),
                  "md:col-span-5": listStyles[index].includes("col-span-5"),
                  "md:col-span-full":
                    listStyles[index].includes("col-span-full"),

                  "rounded-xl bg-white": listStyles[index].includes("bg-white"),
                },
                {
                  "before:text-yellow-300":
                    item.before && item.before.color === "yellow",
                  "before:text-pink-400":
                    item.before && item.before.color === "pink",
                  "before:text-cyan-700":
                    item.before && item.before.color === "cyan",
                  "before:text-indigo-500":
                    item.before && item.before.color === "indigo",
                  "before:text-red-600":
                    item.before && item.before.color === "red",
                  "before:text-green-600":
                    item.before && item.before.color === "green",
                  "before:text-purple-500":
                    item.before && item.before.color === "purple",
                  "before:text-gray-500":
                    item.before && item.before.color === "gray",
                },
                {
                  "my-10 py-16 px-8 before:left-8 before:top-0 before:-translate-y-1/2 before:transform md:my-0":
                    item.before && item.before.position === "top",
                  "pl-28 before:left-0 before:top-0 md:pl-24 lg:before:translate-y-1/3 lg:before:transform":
                    item.before && item.before.position === "left",
                  "pr-28 before:right-4 before:top-0 lg:before:translate-y-1/3 lg:before:transform":
                    item.before && item.before.position === "right",
                  "my-10 py-16 px-8 before:right-8 before:bottom-0 before:translate-y-1/2 before:transform md:my-0":
                    item.before && item.before.position === "bottom",
                }
              )}
            >
              <RichText content={item.text} />
            </li>
          ))}
        </ul>

        {data.button && (
          <ButtonLink
            style="block mx-auto max-w-md"
            button={data.button}
            appearance={getButtonAppearance(data.button.type, "light")}
            key={data.button.id}
            type="button"
          />
        )}
      </div>
    </section>
  )
}

MarkTextList.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    itemList: propTypes.arrayOf(
      propTypes.shape({
        id: propTypes.string.isRequired,
        text: propTypes.string.isRequired,
        style: propTypes.string,
      })
    ),
  }),
}

export default MarkTextList
