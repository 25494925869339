/**
 * It returns the first object in an array that has a name property that matches the inputName argument
 * @param inputName - The name of the input you want to get the config for.
 * @param arr - The array of input objects
 * @returns the first element in the array that matches the inputName. If there is no match, it returns
 * an empty object.
 */
export function getInputConfig(inputName, arr) {
  return arr.find((input) => input.name === inputName) || {};
}

export function getAxiosConfig(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
}
