import PropTypes from "prop-types"
import Link from "next/link"
import classNames from "classnames"
import { useSelector, shallowEqual } from "react-redux"

import LocaleSwitch from "../locale-switch"
import ProfileMenu from "./profile-menu"
import Logo from "./logo"

const Navbar = ({ pageContext }) => {
  const { slug } = pageContext
  const user = useSelector((state) => state.user, shallowEqual)

  return (
    <>
      <nav className="py-4">
        <div className="container flex flex-row items-center justify-between">
          {!user.token ? (
            <Link href="/">
              <a aria-label="home">
                <Logo
                  color={classNames({
                    "text-white": !slug,
                  })}
                />
              </a>
            </Link>
          ) : (
            <Logo
              color={classNames({
                "text-white": !slug,
              })}
            />
          )}
          <div className="flex items-center">
            {/* Locale Switch Mobile */}
            {pageContext.localizedPaths && (
              <div className="md:hidden">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
            {/* Locale Switch Desktop */}
            {pageContext.localizedPaths && (
              <div className="hidden  md:mr-8 md:block">
                <LocaleSwitch pageContext={pageContext} />
              </div>
            )}
            <ProfileMenu pagePath={slug} />
          </div>
        </div>
      </nav>
    </>
  )
}

Navbar.propTypes = {
  initialLocale: PropTypes.string,
}

export default Navbar
