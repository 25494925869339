import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import axios from "axios"
import Cookies from "js-cookie"
import * as yup from "yup"
import { Formik, Form, Field } from "formik"
import Button from "./button"
import { getButtonAppearance } from "../../utils/button"
import Loader from "./loader"
import { useTranslation } from "next-i18next"
import Swal from "sweetalert2"
import * as Sentry from "@sentry/nextjs"

import "react-phone-number-input/style.css"
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import phoneLabels from "../../utils/phoneInputLabels"

import { useDispatch } from "react-redux"
import { loginUser } from "../../redux/userSlice"

const Register = ({ data }) => {
  const router = useRouter()
  const { locale } = router
  const dispatch = useDispatch()
  const { t } = useTranslation(["form", "common", "profile-menu"])

  const [info, setInfo] = useState({ ip: "", country: "" })
  const [showLeeloo, setShowLeeloo] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(false)

  // Get IP Address and Location URL
  const ipURL = "https://ip.nf/me.json"

  // UTM marks list
  const utm_marks = [
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
  ]

  // Set the cookies
  utm_marks.forEach((mark) => {
    const data = router.query[mark]
    if (data) {
      Cookies.set(mark, data)
    }
  })

  //  GET user IP info
  useEffect(async () => {
    let isResponse = true
    await axios.get(ipURL).then((response) => {
      if (isResponse) {
        return setInfo({ ...response.data })
      }
    })

    return () => {
      isResponse = false
    }
  }, [])

  const LeadSchema = yup.object().shape({
    name: yup
      .string()
      .matches(
        /^.[a-zA-Zа-яА-ЯёЁЇїІіЄєҐґ ,.'ʼ`-]{1,19}$/gm,
        t("nameValidation")
      )
      .min(2, t("nameMinLength"))
      .max(20, t("nameMaxLength"))
      .required(t("requiredValidation")),
    phone: yup
      .string()
      .test("test-name", t("phoneValidation"), function (value) {
        if (!value) {
          return false
        }
        return isValidPhoneNumber(value)
      })
      .required(t("requiredValidation")),
    email: yup
      .string()
      .email(t("emailValidation"))
      .required(t("requiredValidation")),
  })

  const initialValuesForm = {
    name: "",
    phone: "",
    email: "",
  }

  async function setQueryParams(leelooHash, name, phone, email) {
    if (leelooHash) {
      // Get utm marks from cookies
      const storedMarks = {}
      utm_marks.forEach((mark) => {
        const data = Cookies.get(mark)
        if (data) {
          storedMarks[mark] = data
        }
      })
      // Adds query params for leeloo form
      await router.push(
        {
          pathname: router.asPath,
          query: { ...storedMarks, name2: name, phone, email },
        },
        undefined,
        { shallow: true }
      )
    }
  }

  function initializeLeeloo() {
    window.LEELOO = function () {
      window.LEELOO_INIT = { id: "5d0cb9cdaad9f4000e4b8e07" }
      var js = document.createElement("script")
      js.src = "https://app.leeloo.ai/init.js"
      js.async = true
      document.getElementsByTagName("head")[0].appendChild(js)
    }
    LEELOO()
    window.LEELOO_LEADGENTOOLS = (window.LEELOO_LEADGENTOOLS || []).concat(
      window.leelooHash
    )
  }

  return (
    <>
      <div className="form__wrapper relative pt-6 pb-20">
        {loading && (
          <div className="absolute inset-0 z-10 grid place-items-center bg-white">
            <Loader />
          </div>
        )}

        {!user && (
          <div className="modal-progress mb-16 hidden">
            <ul className="list modal-progress-bar flex items-start justify-between">
              <li className="item is-active">
                <span>{t("firstStep")}</span>
              </li>
              <li className="item">
                <span>{t("secondStep")}</span>
              </li>
              <li className="item">
                <span>{t("finalStep")}</span>
              </li>
            </ul>
          </div>
        )}

        {!showLeeloo && (
          <>
            {data.title && (
              <h2 className="mb-10 text-center text-4xl font-bold text-hero">
                {data.title}
              </h2>
            )}
            <Formik
              initialValues={initialValuesForm}
              validateOnBlur
              validationSchema={LeadSchema}
              onSubmit={async (
                values,
                { setSubmitting, setErrors, resetForm }
              ) => {
                const { name, phone, email } = values
                const stepList = document.querySelector(".modal-progress-bar")

                setLoading(true)
                try {
                  setErrors({ api: null })
                  await axios
                    .post("/api/auth/register/", {
                      name: name,
                      phone: phone,
                      email: email,
                      info: info,
                      zoho: data.form.zoho,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        dispatch(loginUser(res.data))

                        dataLayer.push({ event: "lead" })
                        setQueryParams(data.form.leelooHash, name, phone, email)
                          .then((result) => {
                            const activeStep =
                              stepList.querySelector(".is-active")
                            const nextStep = stepList.children[1]
                            activeStep.classList.toggle("is-active")
                            nextStep.classList.toggle("is-active")

                            if (data.form.leelooHash) {
                              initializeLeeloo()
                              setShowLeeloo(true)
                            }
                            setUser(true)
                            router.push("/cabinet")
                          })
                          .catch((err) => {
                            setErrors({ api: err.message })
                            console.log(err.message)
                          })
                      }
                    })
                } catch (err) {
                  if (err.response.status === 409) {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: t("emailExists"),
                    })
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: t("error"),
                    })

                    Sentry.captureException(err)
                  }
                  setErrors({ api: err.message })
                  console.log(err.message)
                } finally {
                  setSubmitting(false)
                  resetForm()
                  setLoading(false)
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleBlur,
              }) => (
                <div className="form__inner">
                  <Form className="form">
                    <div className="form__group">
                      <Field
                        className="form__input form__input--name"
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="off"
                        placeholder=" "
                      />
                      <label className="form__label" htmlFor="name">
                        {t("nameLabel")}
                      </label>

                      {errors.name && touched.name && (
                        <p className="form__message">{errors.name}</p>
                      )}
                    </div>

                    <div className="form__group">
                      <PhoneInput
                        className="form__input form__input--phone bg-white"
                        id="phone"
                        labels={phoneLabels[locale]}
                        value={values.phone}
                        international={true}
                        defaultCountry="UA"
                        countries={phoneLabels.countries}
                        withCountryCallingCode={true}
                        onChange={(value) => {
                          setFieldValue("phone", value)
                        }}
                        onBlur={handleBlur}
                      />

                      {errors.phone && touched.phone && (
                        <p className="form__message">{errors.phone}</p>
                      )}
                    </div>

                    <div className="form__group">
                      <Field
                        className="form__input form__input--email"
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        placeholder=" "
                      />
                      <label className="form__label" htmlFor="email">
                        Email
                      </label>

                      {errors.email && touched.email && (
                        <p className="form__message">{errors.email}</p>
                      )}
                    </div>

                    <Button
                      type="submit"
                      button={data.form.submitButton}
                      disabled={isSubmitting}
                      appearance={getButtonAppearance(
                        data.form.submitButton.type,
                        "light"
                      )}
                      style="form__btn"
                    />
                  </Form>
                </div>
              )}
            </Formik>
          </>
        )}

        {showLeeloo && (
          <div className="form__leeloo">
            <div
              className={"wepster-hash-" + data.form.zoho.leelooHash}
              data-leeloo
            ></div>
          </div>
        )}
      </div>
    </>
  )
}

export default Register
